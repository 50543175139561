import React, { useState, useEffect } from 'react';
import { getUserIpInfo } from './../../api';
import { Link } from 'react-router-dom';
import Icon from './../../base_components/Icons';
import { countryCodesArray } from '../../config';
import icons from './img/icons.svg'
import './cookie-popup.scss';

const checkboxItems = [
  {
    title: 'Essentials',
    id: 'essentials',
    checked: true,
    disabled: true,
    cookieKeys: ['ad_storage', 'ad_user_data', 'security_storage'],
  },
  {
    title: 'Marketing',
    id: 'marketing',
    checked: false,
    disabled: false,
    cookieKeys: ['ad_personalization'],
  },
  {
    title: 'Performance',
    id: 'performance',
    checked: false,
    disabled: false,
    cookieKeys: ['analytics_storage'],
  },
  {
    title: 'Functional',
    id: 'functional',
    checked: false,
    disabled: false,
    cookieKeys: ['functionality_storage', 'personalization_storage'],
  },
];

const CookiePopup = () => {
  const [isValidCountry, setIsValidCountry] = useState(false);
  const [hasCookiePopupBeenShown, setHasCookiePopupBeenShown] = useState(true);

  const [showPreferences, setShowPreferences] = useState(false);
  const [checkboxes, setCheckboxes] = useState(checkboxItems);
  const [someCheckboxChecked, setSomeCheckboxChecked] = useState(false);

  const initialCookieValues = (items) => {
    return items.reduce((acc, item) => {
      item.cookieKeys.forEach(cookieKey => {
        acc[cookieKey] = item.checked ? 'granted' : 'denied';
      });
      return acc;
    }, {});
  };

  const [cookies, setCookies] = useState([]);
  const [shouldSendEvent, setShouldSendEvent] = useState(false);

  useEffect(() => {
    const hasCookiePopupBeenShown = localStorage.getItem('consentMode');

    if (!hasCookiePopupBeenShown) {
      getUserIpInfo()
        .then((resp) => {
          const countryCode = resp?.country_code;

          if (!countryCode) {
            return false;
          }

          setIsValidCountry(countryCodesArray.includes(countryCode));
        })
        .catch((error) => error);
    }

    setHasCookiePopupBeenShown(hasCookiePopupBeenShown);
  }, []);

  useEffect(() => {
    if (shouldSendEvent) {
      sendEvent();
      setShouldSendEvent(false);
    }
  }, [cookies]);

  useEffect(() => {
    setCookies(() => initialCookieValues(checkboxes))
  }, [checkboxes]);

  const handleCheckboxChange = (id) => {
    let someCheckboxChecked = false;

    const updatedCheckboxItems = checkboxes.map(item => {
      if (item.id === id) {
        item.checked = !item.checked;
      }
      if (!item.disabled && item.checked) {
        someCheckboxChecked = true;
      }
      return item;
    });

    setCheckboxes(updatedCheckboxItems);
    setSomeCheckboxChecked(someCheckboxChecked);
  };

  const handleAllowAll = () => {
    const updatedCheckboxes = checkboxes.map(checkbox => ({
      ...checkbox,
      checked: true,
    }));

    setCheckboxes(updatedCheckboxes);
    setShouldSendEvent(true);
  };

  const handleDecline = () => {
    const updatedCheckboxes = checkboxes.map(checkbox => ({
      ...checkbox,
      checked: checkbox.disabled ? checkbox.checked : false,
    }));

    setCheckboxes(updatedCheckboxes);

    setShouldSendEvent(true);
  };

  const handleAllowSelection = () => {
    sendEvent();
  };

  const sendEvent = () => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function(){
        window.dataLayer.push(arguments)
      };
      window.gtag('consent', 'update', cookies);
    }
    localStorage.setItem('consentMode', JSON.stringify(cookies));
    setHasCookiePopupBeenShown(true);
  };

  if (!isValidCountry || hasCookiePopupBeenShown) return null;

  return (
    <div className="cookie-popup">
      {showPreferences ? (
        <>
          <div className="titleBlock">
            <p className="title">Cookies preferences</p>

            <button onClick={() => setShowPreferences(false)}>
              <Icon
                className="svg-icon"
                icons={icons}
                iconName="ic_cross"
                width={24}
                height={24}
              />
            </button>
          </div>

          <div className="checkboxBlock">
            {checkboxes.map(item => (
              <div className="checkboxItem" key={item.id}>
                <input
                  id={item.id}
                  type="checkbox"
                  className="hiddenCheckbox"
                  checked={item.checked}
                  disabled={item.disabled}
                  onChange={() => handleCheckboxChange(item.id)}
                />
                <label htmlFor={item.id} className="checkboxLabel">
                  <Icon
                    className="svg-icon"
                    icons={icons}
                    iconName={
                      item.disabled ? "ic_checkbox_disabled" : item.checked ? "ic_checkbox_checked" : 'ic_checkbox'
                    }
                    width={20}
                    height={20}
                  />
                  <p className={`checkboxTitle ${item.disabled && "checkboxTitle_disabled"}`}>{item.title}</p>
                </label>
              </div>
            ))}
          </div>

          <div className="buttons">
            <Link className="button type1 button-ghost" to="/cookie-policy">Show details</Link>
            {someCheckboxChecked ? (
              <button className="button button-tertiary type2" onClick={handleAllowSelection}>Allow selection</button>
            ) : (
              <button className="button button-tertiary type2" onClick={handleDecline}>Decline</button>
            )}
            <button className="button type3" onClick={handleAllowAll}>Allow all</button>
          </div>
        </>
      ) : (
        <>
          <p className="message">
            We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to improve user experience and analyze website traffic. By clicking “Accept,” you agree to our website's cookie use as described in our <Link className="link" to="/cookie-policy">Cookie Policy</Link>. You can change your cookie settings by clicking “<button className="link" onClick={() => setShowPreferences(true)}>Preferences</button>”.
          </p>
          <div className="buttons">
            <button className="button button-ghost type1" onClick={() => setShowPreferences(true)}>Preferences</button>
            <button className="button button-tertiary type2" onClick={handleDecline}>Decline</button>
            <button className="button type3" onClick={handleAllowAll}>Accept</button>
          </div>
        </>
      )}
    </div>
  );
};

export default CookiePopup;
